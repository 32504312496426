<template>
  <v-card elevation="3" class="main-wrapper" min-height="100vh" outlined>
    <v-card-title>Test</v-card-title>
    <v-card-text class="box">
      <v-row dense>
        <v-col cols="12">
          <pre>{{ testMe }}</pre>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import jsonLogic from 'json-logic-js'

export default {
  components: {},
  data: () => ({
    files: [],
    message: 'sssss',
    query: {
      group: true,
      connector: 'and',
      children: [
        {
          variable: '',
          operator: '==',
          compared: ''
        }
      ]
    }
  }),
  computed: {
    ...mapGetters(['user']),
    testMe() {
      var data = { a: 100, b: 'kirby', c: 'apple' }

      var rules = {
        // or: [{ '<=': [{ var: 'a' }, 100] }, { '==': [{ var: 'b' }, 'apple'] }]
        and: [
          jsonLogic.apply(
            {
              and: [true]
            },
            data
          ),
          { '==': [{ var: 'c' }, 'apple'] },
          { '==': [{ var: 'a' }, 100] }
        ]
      }

      return jsonLogic.apply(rules, data)
    }
  },
  created() {},
  methods: {
    enterPressed(e) {
      console.log(e)
    }
  }
}
</script>

<style></style>
